:root.light {
  /* AntD default pallettes ———————————————————— Start */
  --magenta-base: #eb2f96;
  --magenta-1: #fff0f6;
  --magenta-2: #ffd6e7; /* Not used */
  --magenta-3: #ffadd2;
  --magenta-4: #ff85c0; /* Not used */
  --magenta-5: #f759ab; /* Not used */
  --magenta-6: var(--magenta-base);
  --magenta-7: #c41d7f;
  --magenta-8: #9e1068; /* Not used */
  --magenta-9: #780650; /* Not used */
  --magenta-10: #520339; /* Not used */

  --purple-base: #722ed1;
  --purple-1: #f9f0ff;
  --purple-2: #efdbff; /* Not used */
  --purple-3: #d3adf7;
  --purple-4: #b37feb; /* Not used */
  --purple-5: #9254de; /* Not used */
  --purple-6: var(--purple-base);
  --purple-7: #531dab;
  --purple-8: #391085; /* Not used */
  --purple-9: #22075e; /* Not used */
  --purple-10: #120338; /* Not used */

  --cyan-base: #13c2c2;
  --cyan-1: #e6fffb;
  --cyan-2: #b5f5ec; /* Not used */
  --cyan-3: #87e8de;
  --cyan-4: #5cdbd3; /* Not used */
  --cyan-5: #36cfc9; /* Not used */
  --cyan-6: var(--cyan-base);
  --cyan-7: #08979c;
  --cyan-8: #006d75; /* Not used */
  --cyan-9: #00474f; /* Not used */
  --cyan-10: #002329; /* Not used */

  --orange-base: #f6704d;
  --orange-1: #fff7e6;
  --orange-2: #ffe7ba; /* Not used */
  --orange-3: #ffd591;
  --orange-4: #ffc069; /* Not used */
  --orange-5: #ffa940; /* Not used */
  --orange-6: var(--orange-base);
  --orange-7: #d46b08;
  --orange-8: #ad4e00; /* Not used */
  --orange-9: #873800; /* Not used */
  --orange-10: #612500; /* Not used */

  --volcano-base: #fa541c;
  --volcano-1: #fff2e8;
  --volcano-2: #ffd8bf; /* Not used */
  --volcano-3: #ffbb96;
  --volcano-4: #ff9c6e; /* Not used */
  --volcano-5: #ff7a45; /* Not used */
  --volcano-6: var(--volcano-base);
  --volcano-7: #d4380d;
  --volcano-8: #ad2102; /* Not used */
  --volcano-9: #871400; /* Not used */
  --volcano-10: #610b00; /* Not used */

  --geekblue-base: #2f54eb;
  --geekblue-1: #f0f5ff;
  --geekblue-2: #d6e4ff; /* Not used */
  --geekblue-3: #adc6ff;
  --geekblue-4: #85a5ff; /* Not used */
  --geekblue-5: #597ef7; /* Not used */
  --geekblue-6: var(--geekblue-base);
  --geekblue-7: #1d39c4;
  --geekblue-8: #10239e; /* Not used */
  --geekblue-9: #061178; /* Not used */
  --geekblue-10: #030852; /* Not used */

  --lime-base: #a0d911;
  --lime-1: #fcffe6;
  --lime-2: #f4ffb8; /* Not used */
  --lime-3: #eaff8f;
  --lime-4: #d3f261; /* Not used */
  --lime-5: #bae637; /* Not used */
  --lime-6: var(--lime-base);
  --lime-7: #7cb305;
  --lime-8: #5b8c00; /* Not used */
  --lime-9: #3f6600; /* Not used */
  --lime-10: #254000; /* Not used */
  /* AntD default pallettes ———————————————————— End */

  /* Common */
  /* from theme */
  --white: #fff;
  --dark: #111;

  /* Primary  transparent */
  /* from theme */
  --orange-transparent-1: rgba(246, 112, 77, 0.75);
  --orange-transparent-2: rgba(246, 112, 77, 0.5);
  --orange-transparent-3: rgba(246, 112, 77, 0.25);
  --orange-transparent-4: rgba(246, 112, 77, 0.2);
  --orange-transparent-5: rgba(246, 112, 77, 0.15);
  --orange-transparent-6: rgba(246, 112, 77, 0.12);
  --orange-transparent-7: rgba(246, 112, 77, 0.1);
  --orange-transparent-8: rgba(246, 112, 77, 0.08);
  --orange-transparent-9: rgba(246, 112, 77, 0.04);

  /* Dark pure */
  /* from theme */
  --dark-default: var(--dark);
  --dark-1: #4d4d4d;
  --dark-2: #888;
  --dark-3: #c3c3c3;
  --dark-4: #cfcfcf;
  --dark-5: #dcdcdc;
  --dark-6: #e2e2e2;
  --dark-7: #e5e5e5;
  --dark-8: #ececec;
  --dark-9: #e6e6e6;

  /* Dark transparent */
  /* from theme */
  --dark-transparent-1: rgba(17, 17, 17, 0.75);
  --dark-transparent-2: rgba(17, 17, 17, 0.65);
  --dark-transparent-3: rgba(17, 17, 17, 0.25);
  --dark-transparent-4: rgba(17, 17, 17, 0.2);
  --dark-transparent-5: rgba(17, 17, 17, 0.15);
  --dark-transparent-6: rgba(17, 17, 17, 0.12);
  --dark-transparent-7: rgba(17, 17, 17, 0.1);
  --dark-transparent-8: rgba(17, 17, 17, 0.08);
  --dark-transparent-9: rgba(17, 17, 17, 0.04);

  /* White transparent */
  /* from theme */
  --white-transparent-1: rgba(255, 255, 255, 0.75);
  --white-transparent-2: rgba(255, 255, 255, 0.5);
  --white-transparent-3: rgba(255, 255, 255, 0.25);
  --white-transparent-4: rgba(255, 255, 255, 0.2);
  --white-transparent-5: rgba(255, 255, 255, 0.15);
  --white-transparent-6: rgba(255, 255, 255, 0.12);
  --white-transparent-7: rgba(255, 255, 255, 0.1);
  --white-transparent-8: rgba(255, 255, 255, 0.08);
  --white-transparent-9: rgba(255, 255, 255, 0.04);

  /* Success */
  --green-base: #52c41a;
  --green-1: #f6ffed;
  --green-2: #d9f7be; /* Not used */
  --green-3: #b7eb8f;
  --green-4: #95de64; /* Not used */
  --green-5: #73d13d; /* Not used */
  --green-6: var(--green-base);
  --green-7: #389e0d;
  --green-8: #237804; /* Not used */
  --green-9: #135200; /* Not used */
  --green-10: #092b00; /* Not used */

  /* Danger */
  --red-base: #f5222d;
  --red-1: #fff1f0;
  --red-2: #ffccc7;
  --red-3: #ffa39e;
  --red-4: #ff7875;
  --red-5: #ff4d4f; /* Not used */
  --red-6: var(--red-base);
  --red-7: #cf1322;
  --red-8: #a8071a; /* Not used */
  --red-9: #820014; /* Not used */
  --red-10: #5c0011; /* Not used */

  /* Gold */
  --yellow-base: #fadb14;
  --yellow-1: #feffe6;
  --yellow-2: #ffffb8; /* Not used */
  --yellow-3: #fffb8f;
  --yellow-4: #fff566; /* Not used */
  --yellow-5: #ffec3d; /* Not used */
  --yellow-6: var(--yellow-base);
  --yellow-7: #d4b106;
  --yellow-8: #ad8b00; /* Not used */
  --yellow-9: #876800; /* Not used */
  --yellow-10: #614700; /* Not used */

  /* Warning */
  --gold-base: #faad14;
  --gold-1: #fffbe6;
  --gold-2: #fff1b8; /* Not used */
  --gold-3: #ffe58f;
  --gold-4: #ffd666; /* Not used */
  --gold-5: #ffc53d;
  --gold-6: var(--gold-base);
  --gold-7: #d48806;
  --gold-8: #ad6800; /* Not used */
  --gold-9: #874d00; /* Not used */
  --gold-10: #613400; /* Not used */

  --gray-1: #ffffff;
  --gray-2: #fafafa;
  --gray-3: #f5f5f5;
  --gray-4: #f0f0f0;
  --gray-5: #d9d9d9;
  --gray-6: #bfbfbf;
  --gray-7: #8c8c8c; /* Not used */
  --gray-8: #595959;
  --gray-9: #434343; /* Not used */
  --gray-10: #262626; /* Not used */
  --gray-11: #1f1f1f; /* Not used */
  --gray-12: #141414; /* Not used */
  --gray-13: #000000; /* Not used */

  /* Semantic color palettes */

  /* Primary */
  --primary-color: var(--orange-base);
  --primary-1: var(--orange-1);
  --primary-2: var(--orange-2);
  --primary-3: var(--orange-3);
  --primary-4: var(--orange-4);
  --primary-5: var(--orange-5);
  --primary-6: var(--primary-color);
  --primary-7: var(--orange-7);
  --primary-8: var(--orange-8); /* Not used */
  --primary-9: var(--orange-9); /* Not used */
  --primary-10: var(--orange-10); /* Not used */

  --primary-t-lighten1: var(--orange-transparent-1);
  --primary-t-lighten2: var(--orange-transparent-2);
  --primary-t-lighten3: var(--orange-transparent-3);
  --primary-t-lighten4: var(--orange-transparent-4);
  --primary-t-lighten5: var(--orange-transparent-5);
  --primary-t-lighten6: var(--orange-transparent-6);
  --primary-t-lighten7: var(--orange-transparent-7);
  --primary-t-lighten8: var(--orange-transparent-8);

  /* Success */
  --success-color: var(--green-6);
  --success-1: var(--green-1);
  --success-3: var(--green-3);
  --success-7: var(--green-7);

  /* Error */
  --error-color: var(--red-6);
  --error-1: var(--red-1);
  --error-2: var(--red-2);
  --error-3: var(--red-3);
  --error-4: var(--error-color);
  --error-7: var(--red-7);

  /* Warning */
  --warning-color: var(--gold-6);
  --warning-1: var(--gold-1);
  --warning-3: var(--gold-3);
  --warning-5: var(--gold-5);
  --warning-7: var(--gold-7);

  /*  */
  --info-color: var(--primary-color);
  --processing-color: var(--primary-color);
  --highlight-color: var(--red-5);
  --normal-color: #d9d9d9; /* Not used. --> DOUBLE CHECK! */

  /* General settings */
  --theme: light;
  --ant-prefix: ant; /* How to change class-name prefix dinamically? */
  --html-selector: html; /* Not used. --> DOUBLE CHECK! */

  /* Transition */
  --transition-default: all 0.3s;

  /* Grid */
  --grid-columns: 12;
  --grid-gutter-default: 8px;
  --grid-gutter-xs: 8px;
  --grid-gutter-sm: 8px;
  --grid-gutter-md: 12px;
  --grid-gutter-lg: 16px;
  --grid-gutter-xl: 16px;
  --grid-gutter-xxl: 16px;
  --grid-negative-gutter-default: -8px;
  --grid-negative-gutter-sm: -8px;
  --grid-negative-gutter-md: -12px;
  --grid-negative-gutter-lg: -16px;
  --grid-negative-gutter-xl: -16px;
  --grid-negative-gutter-xxl: -16px;
  --grid-container-max-width-sm: 540px;
  --grid-container-max-width-md: 720px;
  --grid-container-max-width-lg: 960px;
  --grid-container-max-width-xl: 1140px;
  --grid-container-max-width-xxl: 1320px;

  /* Layout paddings */
  /* from theme */
  --layout-padding-default: calc(var(--grid-gutter-default) * 2);
  --layout-padding-xs: var(--grid-gutter-xs);
  --layout-padding-sm: calc(var(--grid-gutter-sm) * 2);
  --layout-padding-md: calc(var(--grid-gutter-md) * 2);
  --layout-padding-lg: calc(var(--grid-gutter-lg) * 3);
  --layout-padding-xl: calc(var(--grid-gutter-xl) * 3);
  --layout-padding-xxl: calc(var(--grid-gutter-xxl) * 4);

  /* Base Scaffolding Variables */
  --body-background: var(--white); /* Background color for `<body>` */
  --component-background: var(--white);
  /* Base background color for most components */
  --popover-background: var(
    --component-background
  ); /* Popover background color */
  --popover-customize-border-color: var(--border-color-split);

  /* Gradient */
  /* from theme */
  --gradient-primary: linear-gradient(-45deg, #d0e3f4, #f2edf1);

  /* Border radius */
  /* from theme */
  --border-radius-rounded: 50%;
  --border-radius-base: 8px;
  --border-radius-xxl: 20px;
  --border-radius-xl: 16px;
  --border-radius-lg: 12px;
  --border-radius-md: 8px;
  --border-radius-sm: 4px;
  --border-radius-xs: 2px;

  /* Border */
  --border-color-base: var(--dark-transparent-7);
  --border-color-split: var(--gray-2);
  --border-color-inverse: var(--gray-3); /* FIXME */
  --border-width-base: 1px;
  --border-width-2: 2px;
  --border-style-base: solid;
  --border-style-dashed: dashed;

  /* Typography */

  /* Root */

  /* Font sizes */
  /* from theme */

  --font-size-h1: 40px;
  --font-size-h2: 32px;
  --font-size-h3: 24px;
  --font-size-h4: 20px;
  --font-size-h5: 16px;
  --font-size-h6: 14px;
  --font-size-overline: 12px;
  --font-size-body1: 16px;
  --font-size-body2: 14px;
  --font-size-caption1: 12px;
  --font-size-caption2: 10px;

  /* Line heights */
  /* from theme */
  --line-height-base: 1.5;
  --line-height-h1: 48px;
  --line-height-h2: 40px;
  --line-height-h3: 32px;
  --line-height-h4: 28px;
  --line-height-h5: 24px;
  --line-height-h6: 20px;
  --line-height-overline: 16px;
  --line-height-body1: 24px;
  --line-height-body2: 20px;
  --line-height-caption1: 16px;
  --line-height-caption2: 12px;

  /* Letter spacings */
  /* from theme */
  --letter-spacing-none: 0;
  --letter-spacing-default: 0.6;

  /* Font weights */
  /* from theme */
  --font-weight-bold: 700;
  --font-weight-semibold: 600;
  --font-weight-medium: 500;
  --font-weight-regular: 400;
  --font-weight-light: 300;

  --font-family: "Rubik";
  --code-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier,
    monospace;
  --font-variant-base: tabular-nums;
  --font-feature-settings-base: "tnum";
  --text-color: var(--black);
  --text-color-secondary: var(--gray-8);
  --text-color-inverse: var(--white);
  --text-color-dark: var(--dark-default); /* FIXME */
  --text-color-dark-disabled: var(--dark-transparent-3);
  --text-color-secondary-dark: var(--dark-transparent-2); /* FIXME */
  --text-selection-bg: var(--primary-color);
  --font-size-base: var(--font-size-body1);
  --font-size-lg: var(--font-size-h4);
  --font-size-sm: var(--font-size-body2);
  --heading-color: var(--gray-11);
  --heading-1-size: var(--font-size-h1);
  --heading-2-size: var(--font-size-h2);
  --heading-3-size: var(--font-size-h3);
  --heading-4-size: var(--font-size-h4);
  --heading-5-size: var(--font-size-h5);
  --typography-title-font-weight: var(--font-weight-semibold);
  --typography-title-margin-top: 1.2em;
  --typography-title-margin-bottom: 0;

  /* Iconography */
  --icon-color: var(--black);

  /* Height rules */
  --height-base: 46px;
  --height-lg: 56px;
  --height-sm: 24px;

  /* Vertical paddings */
  --padding-xxl: 32px;
  --padding-xl: 24px;
  --padding-lg: 20px;
  --padding-md: 16px;
  --padding-sm: 12px;
  --padding-xs: 8px;
  --padding-xxs: 4px;

  /* Margins */
  --margin-xxl: 64px;
  --margin-xl: 32px;
  --margin-lg: 24px;
  --margin-md: 16px;
  --margin-sm: 8px;
  --margin-xs: 4px;

  /* Vertical paddings for all form controls */
  --control-padding-horizontal-sm: var(--padding-xs);
  --control-padding-horizontal: var(--padding-sm);
  --control-padding-horizontal-lg: var(--padding-lg);

  /* Layout —————————————————————————————— Start */

  /* + */
  --layout-header-background: var(--white);
  /* + */
  --layout-body-background: var(--gradient-primary);
  /* + */
  --layout-header-height: 64px;
  /* + */
  --layout-header-padding: 0 24px;
  /* + */
  --layout-header-color: var(--text-color);
  /* + */
  --layout-footer-padding: 24px 24px;
  /* + */
  --layout-footer-background: var(--layout-body-background);
  /* + */
  --layout-sider-background: var(--layout-header-background);
  /* + */
  --layout-trigger-height: 48px;
  /* + */
  --layout-trigger-background: #002140; /* FIXME */
  /* + */
  --layout-trigger-color: var(--white);
  /* + */
  --layout-zero-trigger-width: 36px;
  /* + */
  --layout-zero-trigger-height: 42px;
  /* + */
  --layout-sider-background-light: var(--white);
  /* + */
  --layout-trigger-background-light: var(--white);
  /* + */
  --layout-trigger-color-light: var(--text-color);

  /* Layout —————————————————————————————— End */

  /* Progress —————————————————————————————— Start */

  /* + */
  --progress-default-color: var(--processing-color);
  --progress-remaining-color: var(--gray-2);
  /* + */
  --progress-text-color: var(--text-color);
  --progress-info-text-color: var(--progress-text-color);
  /* + */
  --progress-radius: 100px;
  --progress-steps-item-bg: #f3f3f3;
  /* + */
  --progress-text-font-size: 1em;
  /* + */
  --progress-circle-text-font-size: 1em;

  /* Progress —————————————————————————————— End */

  /* Button —————————————————————————————— Start */

  /* + */
  --btn-height-sm: var(--height-sm);
  /* + */
  --btn-height-base: var(--height-base);
  /* + */
  --btn-height-lg: var(--height-lg);

  /* + */
  --btn-square-size-sm: var(--btn-height-sm);
  /* + */
  --btn-square-size: var(--btn-height-base);
  /* + */
  --btn-square-size-lg: var(--btn-height-lg);

  /* + */
  --btn-circle-size-sm: var(--btn-height-sm);
  /* + */
  --btn-circle-size: var(--btn-height-base);
  /* + */
  --btn-circle-size-lg: var(--btn-height-lg);

  /* + */
  --btn-padding-horizontal-sm: calc(var(--control-padding-horizontal-sm) - 1px);
  /* + */
  --btn-padding-horizontal-base: calc(var(--padding-md) - 1px);
  /* + */
  --btn-padding-horizontal-lg: calc(var(--padding-xl) - 1px);

  /* + */
  --btn-shadow: none;

  /* + */
  --btn-font-size-sm: var(--font-size-sm);
  /* + */
  --btn-font-size-base: var(--font-size-base);
  /* + */
  --btn-font-size-lg: var(--font-size-lg);

  --btn-line-height: var(--line-height-base);

  /* + */
  --btn-font-weight: 500;

  /* + */
  --btn-font-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);

  --btn-border-width: var(--border-width-base);
  --btn-border-style: var(--border-style-base);
  --btn-border-color: var(--border-color-base);

  /* + */
  --btn-border-radius-sm: var(--border-radius-sm);
  /* + */
  --btn-border-radius-base: var(--border-radius-base);
  /* + */
  --btn-border-radius-lg: var(--border-radius-base);

  /* + */
  --btn-icon-size-sm: 16px;
  /* + */
  --btn-icon-size: 24px;
  /* + */
  --btn-icon-size-lg: 24px;

  /* + */
  --btn-default-color: var(--dark-transparent-2);
  /* + */
  --btn-default-bg: var(--dark-transparent-8);
  /* + */
  --btn-default-border: transparent;
  /* + */
  --btn-default-hover-border: transparent;
  --btn-default-hover-color: var(--dark);
  --btn-default-hover-bg: var(--dark-transparent-6);

  /* + */
  --btn-primary-color: var(--white);
  /* + */
  --btn-primary-bg: var(--dark);
  /* + */
  --btn-primary-border: transparent;
  /* + */
  --btn-primary-shadow: var(--btn-shadow);
  /* + */
  --btn-primary-hover-border: transparent;
  /* + */
  --btn-primary-hover-bg: var(--dark-transparent-6);
  /* + */
  --btn-primary-hover-color: var(--dark);

  /* + */
  --btn-danger-color: var(--white);
  /* + */
  --btn-danger-bg: var(--error-color);
  /* + */
  --btn-danger-border: transparent;
  /* + */
  --btn-danger-shadow: var(--btn-shadow);

  /* + */
  --btn-white-color: var(--text-color-secondary);
  /* + */
  --btn-white-bg: var(--white);
  /* + */
  --btn-white-border: var(--white);
  /* + */
  --btn-white-shadow: var(--btn-shadow);
  /* + */
  --btn-white-hover-bg: var(--btn-white-bg);
  /* + */
  --btn-white-hover-border: var(--white);
  --btn-white-hover-color: var(--dark);

  /* + */
  --btn-text-color: var(--text-color);
  /* + */
  --btn-text-bg: transparent;
  /* + */
  --btn-text-border: transparent;
  /* + */
  --btn-text-shadow: var(--btn-shadow);
  /* + */
  --btn-text-hover-bg: var(--dark-transparent-8);
  /* + */
  --btn-text-hover-color: var(--text-color);

  /* + */
  --btn-default-ghost-color: var(--white);
  /* + */
  --btn-default-ghost-bg: transparent;
  /* + */
  --btn-default-ghost-border: var(--white);

  /* + */
  --btn-link-color: var(--primary-color);
  /* + */
  --btn-link-bg: transparent;
  /* + */
  --btn-link-border: transparent;
  /* + */
  --btn-link-shadow: var(--btn-shadow);
  /* + */
  --btn-link-hover-bg: transparent;

  /* + */
  --btn-disable-color: var(--disabled-color);
  /* + */
  --btn-disable-bg: var(--disabled-bg);
  /* + */
  --btn-disable-border: var(--dark-transparent-5);
  /* + */
  --btn-disable-shadow: var(--btn-shadow);
  /* + */
  --btn-disable-font-shadow: none;

  /* + */
  --btn-group-border: var(--primary-5);

  /* Button —————————————————————————————— End */

  /* Input —————————————————————————————— Start */

  --input-height-sm: var(--height-sm);
  --input-height-base: var(--height-base);
  --input-height-lg: var(--height-lg);

  /* + */
  --input-padding-horizontal: calc(var(--control-padding-horizontal) - 1px);
  /* + */
  --input-padding-horizontal-sm: calc(
    var(--control-padding-horizontal-sm) - 1px
  );
  /* + */
  --input-padding-horizontal-base: calc(var(--padding-md) - 1px);
  /* + */
  --input-padding-horizontal-lg: calc(
    var(--control-padding-horizontal-lg) - 1px
  );

  /* + */
  --input-padding-vertical-sm: calc(
    (
        var(--input-height-sm) - var(--font-size-sm) * var(--line-height-base) -
          var(--border-width-base) * 2
      ) / 2
  );
  /* + */
  /* + */
  --input-padding-vertical-base: calc(
    (
        var(--input-height-base) - var(--font-size-base) *
          var(--line-height-base) - var(--border-width-base) * 2
      ) / 2
  );
  /* + */
  --input-padding-vertical-lg: calc(
    (
        var(--input-height-lg) - var(--font-size-lg) * var(--line-height-base) -
          var(--border-width-base) * 2
      ) / 2
  );

  /* + */
  --input-bg: var(--dark-transparent-8);
  /* + */
  --input-disabled-bg: var(--disabled-bg);
  /* + */
  --input-addon-bg: var(--background-color-light);

  /* + */
  --input-border-color: transparent;
  /* + */
  --input-hover-border-color: var(--primary-color);

  /* + */
  --input-border-radius-sm: var(--border-radius-sm);
  /* + */
  --input-border-radius-base: var(--border-radius-base);
  /* + */
  --input-border-radius-lg: var(--border-radius-base);

  --input-outline-offset: 0 0;

  /* + */
  --input-color: var(--text-color);
  /* + */
  --input-disabled-color: var(--disabled-color);

  --input-icon-color: var(--input-color);
  --input-icon-hover-color: var(--gray-11);
  /* + */
  --input-placeholder-color: rgb(91, 92, 94); /* FIXME */

  /* + */
  --input-number-hover-border-color: var(--input-hover-border-color);
  /* + */
  --input-number-handler-active-bg: #f4f4f4;
  --input-number-handler-hover-bg: var(--primary-color);
  /* + */
  --input-number-handler-bg: var(--component-background);
  /* + */
  --input-number-handler-border-color: var(--border-color-base);

  /*
    The background colors for active and hover states for things like
    list items or table cells.
  */
  --item-active-bg: var(--primary-1);
  --item-hover-bg: var(--primary-color);

  /* Input —————————————————————————————— End */

  /* Select —————————————————————————————— Start */

  --select-border-color: var(--border-color-base);
  --select-item-selected-color: var(--text-color);
  --select-item-selected-font-weight: 400;
  --select-dropdown-bg: var(--component-background);
  --select-item-selected-bg: var(--primary-1);
  --select-item-active-bg: var(--item-hover-bg);
  --select-item-hover: var(--gray-3);
  --select-dropdown-vertical-padding: var(--dropdown-vertical-padding);
  --select-dropdown-font-size: var(--dropdown-font-size);
  --select-dropdown-line-height: var(--dropdown-line-height);
  --select-dropdown-height: 32px;
  --select-background: var(--gray-1);
  --select-clear-background: var(--select-background);
  --select-selection-item-bg: var(--primary-color);
  --select-open-selection-item-color: var(--text-color-secondary-dark);
  --select-selection-item-border-color: var(--border-color-split);
  --select-single-item-height-lg: var(--height-lg);
  /* --select-multiple-item-height: @input-height-base - @input-padding-vertical-base * 2; */
  --select-multiple-item-height-lg: 40px;
  /* --select-multiple-item-spacing-half: ceil((@input-padding-vertical-base / 2)); */
  --select-multiple-disabled-background: var(--input-disabled-bg);
  --select-multiple-item-disabled-color: #bfbfbf;
  --select-multiple-item-disabled-border-color: var(--select-border-color);

  /* Select —————————————————————————————— End */

  /* List —————————————————————————————— Start */

  /* + */
  --list-header-background: transparent;
  /* + */
  --list-footer-background: transparent;
  /* + */
  --list-empty-text-padding: var(--padding-md);
  /* + */
  --list-item-padding: var(--padding-sm) 0;
  /* + */
  --list-item-padding-sm: var(--padding-xs) var(--padding-md);
  /* + */
  --list-item-padding-lg: var(--padding-md) var(--padding-lg);
  --list-item-meta-margin-bottom: var(--padding-md);
  /* + */
  --list-item-meta-avatar-margin-right: var(--padding-md);
  /* + */
  --list-item-meta-title-margin-bottom: var(--padding-sm);
  /* + */
  --list-item-meta-description-font-size: var(--font-size-base);
  --list-customize-card-bg: var(--component-background);

  --list-item-border-color: var(--dark-transparent-7);
  /* List —————————————————————————————— End */

  /* Menu —————————————————————————————— Start */

  /* + */
  --menu-bg: transparent;
  /* + */
  --menu-padding: var(--padding-xs) 0;
  /* + */
  --menu-highlight-color: var(--primary-color);
  /* + */
  --menu-highlight-danger-color: var(--error-color);

  /* + */
  --menu-inline-collapsed-width: calc(
    var(--ql-aside-collapsed-width) - var(--ql-aside-border-width)
  );
  /* + */
  --menu-inline-submenu-bg: var(--gray-2);

  /* + */
  --menu-horizontal-height: var(--ql-header-height);
  /* + */
  --menu-horizontal-border-bottom: unset;

  /* + */
  --menu-horizontal-main-item-spacing: 0 0;
  /* + */
  --menu-horizontal-main-item-padding: 0 16px;
  /* + */
  --menu-horizontal-main-item-color-hover: var(--text-color-dark);
  /* + */
  --menu-horizontal-main-item-box-shadow-hover: inset 0 -2px var(--menu-highlight-color);
  /* + */
  --menu-horizontal-main-item-danger-color-hover: var(
    --menu-highlight-danger-color
  );
  /* + */
  --menu-horizontal-main-item-danger-box-shadow-hover: inset 0 -2px var(--menu-highlight-danger-color);

  /* + */
  --menu-item-height: 48px;
  /* + */
  --menu-item-font-size: var(--font-size-base);
  /* + */
  --menu-item-line-height: var(--line-height-base);
  /* + */
  --menu-item-color: var(--text-color-secondary);
  /* + */
  --menu-item-padding: calc(
      (var(--menu-item-height) - var(--line-height-base)) / 2
    )
    20px;
  /* + */
  --menu-item-vertical-margin: 0;
  /* + */
  --menu-item-boundary-margin: 0;
  /* + */
  --menu-item-icon-size: var(--menu-item-font-size);
  /* + */
  --menu-item-icon-margin-right: 10px;
  /* + */
  --menu-item-active-bg: var(--primary-1);
  /* + */
  --menu-item-active-danger-bg: var(--red-1);
  /* + */
  --menu-item-active-border-right: 3px solid var(--menu-highlight-color);

  /* + */
  --menu-submenu-bg: var(--component-background);
  /* + */
  --menu-submenu-border-radius: var(--border-radius-sm);

  /* + */
  --menu-group-title-font-size: var(--font-size-caption1);
  /* + */
  --menu-group-title-line-height: var(--line-height-caption1);
  /* + */
  --menu-group-title-font-weight: var(--font-weight-medium);
  /* + */
  --menu-group-title-letter-spacing: var(--letter-spacing-default);
  /* + */
  --menu-group-title-text-transform: uppercase;
  /* + */
  --menu-group-title-color: var(--disabled-color);
  /* + */
  --menu-group-title-padding: var(--menu-item-padding);

  --menu-item-group-height: var(--line-height-base);
  --menu-item-active-border-width: 3px;
  --menu-inline-toplevel-item-height: 40px;

  /* Menu —————————————————————————————— End */

  /* Card —————————————————————————————— Start */

  /* + */
  --card-head-color: var(--heading-color);
  /* + */
  --card-head-background: transparent;
  /* + */
  --card-head-font-size: var(--font-size-lg);
  /* + */
  --card-head-font-size-sm: var(--font-size-base);
  /* + */
  --card-head-padding: 16px;
  /* + */
  --card-head-padding-sm: calc(var(--card-head-padding) / 2);
  /* + */
  --card-head-height: 48px;
  /* + */
  --card-head-height-sm: 36px;
  /* + */
  --card-inner-head-padding: 12px;
  --card-padding-base: 24px;
  --card-padding-base-sm: calc(var(--card-padding-base) / 2);
  /* + */
  --card-actions-background: var(--component-background);
  /* + */
  --card-actions-li-margin: 12px 0;
  /* + */
  --card-background: var(--white-transparent-2);
  /* + */
  --card-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
  /* + */
  --card-radius: var(--border-radius-lg);
  /* + */
  --card-head-tabs-margin-bottom: -17px;
  /* + */
  --card-head-extra-color: var(--text-color);
  --card-skeleton-bg: #cfd8dc;

  /* Card —————————————————————————————— End */

  /* Skeleton —————————————————————————————— Start */

  --skeleton-color: var(--gray-1);
  --skeleton-to-color: var(--gray-5);
  /* + */
  --skeleton-paragraph-margin-top: 28px;
  /* + */
  --skeleton-paragraph-li-margin-top: var(--margin-md); /* FIXME */
  /* + */
  --skeleton-paragraph-li-height: 16px;
  /* + */
  --skeleton-title-height: 16px;
  /* + */
  --skeleton-title-paragraph-margin-top: var(--margin-lg); /* FIXME */

  /* Skeleton —————————————————————————————— End */

  /* Popover —————————————————————————————— Start */

  /* + */
  --popover-min-width: 177px;
  /* + */
  --popover-min-height: 32px;
  /* + */
  --popover-bg: var(--component-background);
  /* + */
  --popover-color: var(--text-color);
  --popover-padding-horizontal: var(--padding-lg);
  --popover-distance: calc(var(--popover-arrow-width) + 4px);
  --popover-arrow-width: 6px;
  --popover-arrow-color: var(--popover-bg);
  --popover-arrow-outer-color: var(--popover-bg);

  /* Popover —————————————————————————————— End */

  /* Iconfont —————————————————————————————— Start */

  --iconfont-css-prefix: "anticon";

  /* Iconfont —————————————————————————————— End */

  /* Link —————————————————————————————— Start */

  /* + */
  --link-color: var(--primary-color);
  /* + */
  --link-hover-color: var(--primary-5);
  /* + */
  --link-active-color: var(--primary-7);
  /* + */
  --link-decoration: none;
  /* + */
  --link-hover-decoration: none;
  /* + */
  --link-focus-decoration: none;
  /* + */
  --link-focus-outline: 0;

  /* Link —————————————————————————————— End */

  /* Animation —————————————————————————————— Start */

  /* + */
  --ease-base-out: cubic-bezier(0.7, 0.3, 0.1, 1);
  --ease-base-in: cubic-bezier(0.9, 0, 0.3, 0.7);
  /* + */
  --ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
  --ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  /* + */
  --ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
  /* + */
  --ease-out-back: cubic-bezier(0.12, 0.4, 0.29, 1.46);
  /* + */
  --ease-in-back: cubic-bezier(0.71, -0.46, 0.88, 0.6);
  --ease-in-out-back: cubic-bezier(0.71, -0.46, 0.29, 1.46);
  /* + */
  --ease-out-circ: cubic-bezier(0.08, 0.82, 0.17, 1);
  /* + */
  --ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  /* + */
  --ease-in-out-circ: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  /* + */
  --ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
  /* + */
  --ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  --ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);

  /* Animation —————————————————————————————— End */

  /* Outline —————————————————————————————— Start */

  --outline-blur-size: 0;
  --outline-width: 2px;
  --outline-opacity: 20%;
  --outline-style: solid;

  /* + */
  --outline-color-default: 38 38 38; /* FIXME: reference to grey color or generate with JS */
  /* + */
  --outline-color-primary: 246 112 77;
  /* + */
  --outline-color-warning: 250 173 20; /* FIXME: reference to warning color or generate with JS */
  /* + */
  --outline-color-error: 255 77 79; /* FIXME: reference to error color or generate with JS */

  /* + */
  --outline-default: 0 0 var(--outline-blur-size) var(--outline-width)
    rgba(var(--outline-color-default) / var(--outline-opacity));
  /* + */
  --outline-primary: 0 0 var(--outline-blur-size) var(--outline-width)
    rgba(var(--outline-color-primary) / var(--outline-opacity));
  /* + */
  --outline-warning: 0 0 var(--outline-blur-size) var(--outline-width)
    rgba(var(--outline-color-warning) / var(--outline-opacity));
  /* + */
  --outline-error: 0 0 var(--outline-blur-size) var(--outline-width)
    rgba(var(--outline-color-error) / var(--outline-opacity));

  /* Outline —————————————————————————————— End */

  --background-color-light: var(--gray-2);
  --background-color-base: var(--gray-3);

  /* Disabled states */
  --disabled-color: var(--gray-7);
  --disabled-bg: var(--dark-transparent-9);
  --disabled-color-dark: var(--gray-3);

  /* Shadow */
  --shadow-1-up: 0 -6px 16px -8px rgba(0, 0, 0, 0.08),
    0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03);
  --shadow-1-down: 0 6px 16px -8px rgba(0, 0, 0, 0.08),
    0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
  --shadow-1-left: -6px 0 16px -8px rgba(0, 0, 0, 0.08),
    -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
  --shadow-1-right: 6px 0 16px -8px rgba(0, 0, 0, 0.08),
    9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03);
  --shadow-2: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  --box-shadow-base: var(--shadow-2);
  --box-shadow-color-dropdown-arrow: rgba(0, 0, 0, 0.07);
  --shadow-color: rgba(0, 0, 0, 0.15);
  --shadow-color-inverse: var(--component-background);

  /* Checkbox —————————————————————————————— Start */

  /* + */
  --checkbox-size: 16px;
  /* + */
  --checkbox-border-width: var(--border-width-base);
  --checkbox-color: var(--primary-color);
  --checkbox-check-color: var(--white);
  --checkbox-check-bg: var(--checkbox-check-color);
  --checkbox-group-item-margin-right: 8px;

  /* Checkbox —————————————————————————————— End */

  /* Descriptions —————————————————————————————— Start */

  --descriptions-bg: #fafafa;
  /* + */
  --descriptions-title-margin-bottom: 20px;
  /* + */
  --descriptions-default-padding: var(--padding-md) var(--padding-lg);
  /* + */
  --descriptions-middle-padding: var(--padding-sm) var(--padding-lg);
  /* + */
  --descriptions-small-padding: var(--padding-xs) var(--padding-md);
  /* + */
  --descriptions-item-padding-bottom: var(--padding-md);
  --descriptions-item-trailing-colon: true;
  /* + */
  --descriptions-item-label-colon-margin-right: 8px;
  /* + */
  --descriptions-item-label-colon-margin-left: 2px;
  /* + */
  --descriptions-extra-color: var(--text-color);

  /* Descriptions —————————————————————————————— End */

  /* Divider —————————————————————————————— Start */

  /* + */
  --divider-text-padding: 1em;
  /* + */
  --divider-orientation-margin: 5%;
  /* + */
  --divider-color: rgba(0, 0, 0, 0.06);

  /* Divider —————————————————————————————— End */

  /* Dropdown —————————————————————————————— Start */

  --dropdown-menu-bg: var(--component-background);
  /* + */
  --dropdown-menu-border-radius: var(--border-radius-sm);
  /* + */
  --dropdown-menu-shadow: var(--box-shadow-base);
  /* + */
  --dropdown-menu-padding: var(--padding-xxs) 0;

  --dropdown-edge-child-vertical-padding: 4px;

  --dropdown-vertical-padding: 5px;

  /* + */
  --dropdown-font-size: var(--font-size-base);

  /* + */
  --dropdown-line-height: 22px;

  /* + */
  --dropdown-item-font-size: var(--font-size-base);
  /* + */
  --dropdown-item-line-height: var(--line-height-base);
  /* + */
  --dropdown-item-font-weight: var(--font-weight-regular);
  /* + */
  --dropdown-item-color: var(--text-color-secondary);

  /* + */
  --dropdown-item-padding: 0 var(--padding-md);

  /* + */
  --dropdown-item-margin-bottom: 0;
  /* + */
  --dropdown-item-margin-bottom-last-child: 0;

  /* + */
  --dropdown-item-border-radius: unset;
  /* + */
  --dropdown-item-border-radius-first-child: unset;
  /* + */
  --dropdown-item-border-radius-last-child: unset;

  /* + */
  --dropdown-item-icon-size: var(--font-size-base);
  /* + */
  --dropdown-item-icon-margin-right: 16px;

  /* + */
  --dropdown-item-default-bg-hover: var(--dark-transparent-9);

  /* + */
  --dropdown-item-danger-color: var(--error-color);
  /* + */
  --dropdown-item-danger-color-hover: var(--error-color);
  /* + */
  --dropdown-item-danger-bg-hover:  rgba(255,0,0,0.15);

  /* + */
  --dropdown-item-disabled-color: var(--disabled-color);
  /* + */
  --dropdown-item-disabled-bg: var(--component-background);

  /* + */
  --dropdown-selected-color: var(--primary-color);

  /* + */
  --dropdown-group-margin: 0 0 0 20px;

  /* + */
  --dropdown-group-title-font-size: var(--menu-group-title-font-size);
  /* + */
  --dropdown-group-title-line-height: var(--menu-group-title-line-height);
  /* + */
  --dropdown-group-title-font-weight: var(--menu-group-title-font-weight);
  /* + */
  --dropdown-group-title-letter-spacing: var(--menu-group-title-letter-spacing);
  /* + */
  --dropdown-group-title-text-transform: var(--menu-group-title-text-transform);
  /* + */
  --dropdown-group-title-color: var(--menu-group-title-color);
  /* + */
  --dropdown-group-title-padding: var(--dropdown-item-padding);

  /* + */
  --dropdown-group-item-border-radius: 20px 0 0 20px;

  /* + */
  --dropdown-menu-submenu-disabled-bg: var(--component-background);

  /* Dropdown —————————————————————————————— End */

  /* Empty —————————————————————————————— Start */

  /* + */
  --empty-font-size: var(--font-size-base);

  /* Empty —————————————————————————————— End */

  /* Radio —————————————————————————————— Start */

  /* + */
  --radio-size: 16px;
  /* + */
  --radio-top: 0.2em;
  /* + */
  --radio-border-width: 1px;
  /* + */
  --radio-dot-size: calc(var(--radio-size) - 8px);
  /* + */
  --radio-dot-color: var(--primary-color);
  /* + */
  --radio-dot-disabled-color: rgba(0, 0, 0, 0.2);
  --radio-solid-checked-color: var(--component-background);
  --radio-button-bg: var(--btn-default-bg);
  --radio-button-checked-bg: var(--btn-default-bg);
  --radio-button-color: var(--btn-default-color);
  --radio-button-hover-color: var(--primary-5);
  --radio-button-active-color: var(--primary-7);
  --radio-disabled-button-checked-bg: rgba(0 0 0, 0.1);
  --radio-disabled-button-checked-color: var(--disabled-color);
  /* + */
  --radio-wrapper-margin-right: 8px;

  /* Radio —————————————————————————————— End */

  /* Breakpoints —————————————————————————————— Start */

  /*** Status: impossible to use */
  /* from theme */
  --screen-sm: 576px;
  --screen-sm-min: var(--screen-sm);
  --screen-md: 768px;
  --screen-md-min: var(--screen-md);
  --screen-lg: 1024px;
  --screen-lg-min: var(--screen-lg);
  --screen-xl: 1200px;
  --screen-xl-min: var(--screen-xl);
  --screen-xxl: 1600px;
  --screen-xxl-min: var(--screen-xxl);
  /* --screen-xs-max: NOT_MATCH; */
  /* --screen-sm-max: NOT_MATCH; */
  /* --screen-md-max: NOT_MATCH; */
  /* --screen-lg-max: NOT_MATCH; */
  /* --screen-xl-max: NOT_MATCH; */

  /* Breakpoints —————————————————————————————— End */

  /* Z-index —————————————————————————————— Start */

  /* + */
  --zindex-badge: auto;
  /* + */
  --zindex-table-fixed: 2;
  /* + */
  --zindex-affix: 10;
  /* + */
  --zindex-back-top: 10;
  /* + */
  --zindex-picker-panel: 10;
  /* + */
  --zindex-popup-close: 10;
  /* + */
  --zindex-modal: 1000;
  /* + */
  --zindex-modal-mask: 1000;
  /* + */
  --zindex-message: 1010;
  /* + */
  --zindex-notification: 1010;
  /* + */
  --zindex-popover: 1030;
  /* + */
  --zindex-dropdown: 1050;
  --zindex-picker: 1050;
  /* + */
  --zindex-popoconfirm: 1060;
  /* + */
  --zindex-tooltip: 1070;
  /* + */
  --zindex-image: 1080;

  /* Z-index —————————————————————————————— End */

  /* Animation —————————————————————————————— Start */

  /* + */
  --animation-duration-slow: 0.3s;
  /* + */
  --animation-duration-base: 0.2s;
  /* + */
  --animation-duration-fast: 0.1s;

  /* Animation —————————————————————————————— End */

  /* Collapse —————————————————————————————— Start */

  --collapse-panel-border-radius: var(--border-radius-base);

  /* Collapse —————————————————————————————— End */

  /* Label —————————————————————————————— Start */

  --label-required-color: var(--highlight-color);
  --label-color: var(--heading-color);

  /* Label —————————————————————————————— End */

  /* Form —————————————————————————————— Start */

  /* --form-item-trailing-colon: true; */
  /* + */
  --form-item-margin-bottom: 24px;
  /* + */
  --form-vertical-label-padding: 0 0 8px;
  /* + */
  --form-vertical-label-margin: 0;
  --form-item-label-font-size: var(--font-size-base);
  --form-item-label-height: var(--input-height-base);
  --form-item-label-colon-margin-right: 8px;
  --form-item-label-colon-margin-left: 2px;
  --form-error-input-bg: var(--input-bg);
  --form-warning-input-bg: var(--input-bg);

  /* Form —————————————————————————————— End */

  /* Mentions —————————————————————————————— Start */

  /* + */
  --mentions-dropdown-bg: var(--component-background);
  /* + */
  --mentions-dropdown-menu-item-hover-bg: var(--mentions-dropdown-bg);

  /* Mentions —————————————————————————————— End */

  /* Cascader —————————————————————————————— Start */

  /* + */
  --cascader-bg: var(--component-background);
  /* + */
  --cascader-item-selected-bg: var(--primary-1);
  /* + */
  --cascader-menu-bg: var(--component-background);
  /* + */
  --cascader-menu-border-color-split: var(--border-color-split);
  /* + */
  --cascader-dropdown-vertical-padding: var(--dropdown-vertical-padding);
  /* + */
  --cascader-dropdown-edge-child-vertical-padding: var(
    --dropdown-edge-child-vertical-padding
  );
  /* + */
  --cascader-dropdown-font-size: var(--dropdown-font-size);
  /* + */
  --cascader-dropdown-line-height: var(--dropdown-line-height);

  /* Cascader —————————————————————————————— End */

  /* Anchor —————————————————————————————— Start */

  /* + */
  --anchor-bg: var(--component-background);
  --anchor-border-color: var(--border-color-split);
  /* + */
  --anchor-link-top: 7px;
  /* + */
  --anchor-link-left: 16px;
  /* + */
  --anchor-link-padding: var(--anchor-link-top) 0 var(--anchor-link-top)
    var(--anchor-link-left);

  /* Anchor —————————————————————————————— End */

  /* Tooltip —————————————————————————————— Start */

  /* + */
  --tooltip-max-width: 250px;
  /* + */
  --tooltip-color: var(--white);
  /* + */
  --tooltip-bg: var(--gray-9);
  /* + */
  --tooltip-arrow-width: 5px;
  /* + */
  --tooltip-arrow-color: var(--tooltip-bg);
  /* + */
  --tooltip-distance: calc(var(--tooltip-arrow-width) - 1px + 4px);

  /* Tooltip —————————————————————————————— End */

  /* Modal —————————————————————————————— Start */

  /* + */
  --modal-header-padding-vertical: var(--padding-md);
  /* + */
  --modal-header-padding-horizontal: var(--padding-lg);
  /* + */
  --modal-header-padding: var(--modal-header-padding-vertical)
    var(--modal-header-padding-horizontal);
  /* + */
  --modal-body-padding: var(--padding-lg);
  /* + */
  --modal-header-bg: var(--component-background);
  /* + */
  --modal-header-border-width: var(--border-width-base);
  /* + */
  --modal-header-border-style: var(--border-style-base);
  /* + */
  --modal-header-title-line-height: 22px;
  /* + */
  --modal-header-title-font-size: var(--font-size-lg);
  /* + */
  --modal-header-border-color-split: var(--border-color-split);
  /* + */
  --modal-header-close-size: 56px;
  /* + */
  --modal-content-bg: var(--component-background);
  /* + */
  --modal-heading-color: var(--heading-color);
  /* + */
  --modal-close-color: var(--text-color-secondary);
  /* + */
  --modal-footer-bg: transparent;
  /* + */
  --modal-footer-border-width: var(--border-width-base);
  /* + */
  --modal-footer-border-style: var(--border-style-base);
  /* + */
  --modal-footer-border-color-split: var(--border-color-split);
  /* + */
  --modal-footer-padding-vertical: 10px;
  /* + */
  --modal-footer-padding-horizontal: 16px;
  /* + */
  --modal-mask-bg: var(--text-color-secondary);
  /* + */
  --modal-confirm-body-padding: 32px 32px 24px;

  /* Modal —————————————————————————————— End */

  /* Spin —————————————————————————————— Start */

  /* + */
  --spin-dot-size-sm: 14px;
  /* + */
  --spin-dot-size: 20px;
  /* + */
  --spin-dot-size-lg: 32px;

  /* Spin —————————————————————————————— End */

  /* Table */
  --table-bg: var(--gray-2);
  --table-hover-bg: var(--card-background);
  --table-header-bg: var(--gray-4);
  --table-header-color: var(--heading-color);
  --table-header-sort-bg: var(--background-color-base);
  --table-body-selected-sort-bg: var(--table-selected-row-bg);
  --table-body-sort-bg: #fafafa;
  --table-row-hover-bg: var(--background-color-light);
  --table-selected-row-color: inherit;
  --table-selected-row-bg: var(--primary-1);
  --table-selected-row-hover-bg: var(--primary-1);
  /* --table-selected-row-hover-bg: darken(@table-selected-row-bg, 2%); */
  --table-expanded-row-bg: #fbfbfb;
  --table-padding-vertical: 16px;
  --table-padding-horizontal: 16px;
  /* --table-padding-vertical-md: (@table-padding-vertical * 3 / 4); */
  /* --table-padding-horizontal-md: (@table-padding-horizontal / 2); */
  /* --table-padding-vertical-sm: (@table-padding-vertical / 2); */
  /* --table-padding-horizontal-sm: (@table-padding-horizontal / 2); */
  --table-border-color: var(--border-color-split);
  --table-border-radius-base: var(--border-radius-base);
  --table-footer-bg: var(--background-color-light);
  --table-footer-color: var(--heading-color);
  --table-header-bg-sm: var(--table-header-bg);
  --table-font-size: var(--font-size-base);
  --table-font-size-md: var(--table-font-size);
  --table-font-size-sm: var(--table-font-size);
  /* --table-header-sort-active-bg: rgba(0, 0, 0, 0.04); */
  /* --table-header-filter-active-bg: rgba(0, 0, 0, 0.04); */
  --table-filter-btns-bg: inherit;
  --table-filter-dropdown-bg: var(--component-background);
  --table-expand-icon-bg: var(--component-background);
  --table-selection-column-width: 32px;
  /* --table-sticky-scroll-bar-bg: fade(#000, 35%); */
  --table-sticky-scroll-bar-radius: 4px;

  /* Table —————————————————————————————— End */

  /* Tag —————————————————————————————— Start */

  /* + */
  --tag-default-bg: var(--background-color-light);
  /* + */
  --tag-default-color: var(--text-color);
  /* + */
  --tag-font-size: var(--font-size-sm);
  /* + */
  --tag-line-height: 20px;

  --tag-default-border-color: transparent;

  /* Tag —————————————————————————————— End */

  /* Picker */
  --picker-bg: var(--component-background);
  --picker-basic-cell-hover-color: var(--item-hover-bg);
  --picker-basic-cell-active-with-range-color: var(--primary-1);
  --picker-basic-cell-hover-with-range-color: var(
    --primary-4
  ); /*FIXME: lighten(@primary-color, 35%)*/
  --picker-basic-cell-disabled-bg: var(--disabled-bg);
  --picker-border-color: var(--border-color-split);
  /* --picker-date-hover-range-border-color: lighten(@primary-color, 20%); */
  --picker-date-hover-range-color: var(
    --picker-basic-cell-hover-with-range-color
  );
  --picker-time-panel-column-width: 56px;
  --picker-time-panel-column-height: 224px;
  --picker-time-panel-cell-height: 28px;
  --picker-panel-cell-height: 24px;
  --picker-panel-cell-width: 36px;
  --picker-text-height: 40px;
  --picker-panel-without-time-cell-height: 66px;

  /* Calendar —————————————————————————————— Start */

  /* + */
  --calendar-bg: var(--component-background);
  --calendar-input-bg: var(--input-bg);
  --calendar-border-color: var(--border-color-inverse);
  --calendar-item-active-bg: var(--item-active-bg);
  --calendar-full-bg: var(--calendar-bg);
  /* + */
  --calendar-full-panel-bg: var(--calendar-full-bg);
  --carousel-dot-width: 16px;
  --carousel-dot-height: 3px;
  --carousel-dot-active-width: 24px;

  /* Calendar —————————————————————————————— End */

  /* Badge —————————————————————————————— Start */

  /* + */
  --badge-height: 20px;
  /* + */
  --badge-height-sm: 14px;
  /* + */
  --badge-dot-size: 6px;
  /* + */
  --badge-font-size: var(--font-size-caption1);
  /* + */
  --badge-font-size-sm: var(--font-size-caption2);
  /* + */
  --badge-font-weight: var(--font-weight-medium);
  /* + */
  --badge-status-size: 6px;
  /* + */
  --badge-text-color: var(--component-background);
  /* + */
  --badge-color: var(--primary-color);

  /* Badge —————————————————————————————— End */

  /* Rate —————————————————————————————— Start */

  /* + */
  --rate-star-color: var(--yellow-6);
  --rate-star-bg: var(--border-color-split);
  /* + */
  --rate-star-size: 20px;
  /* + */
  --rate-star-hover-scale: scale(1.1);

  /* Rate —————————————————————————————— End */

  /* Comment —————————————————————————————— Start */

  /* + */
  --comment-bg: inherit;
  /* + */
  --comment-padding-base: var(--padding-md) 0;
  /* + */
  --comment-nest-indent: 44px;
  /* + */
  --comment-font-size-base: var(--font-size-base);
  /* + */
  --comment-font-size-sm: var(--font-size-sm);
  /* + */
  --comment-author-name-color: var(--text-color-secondary);
  /* + */
  --comment-author-time-color: #ccc;
  /* + */
  --comment-action-color: var(--text-color-secondary);
  /* + */
  --comment-action-hover-color: var(--gray-8);
  /* + */
  --comment-actions-margin-bottom: inherit;
  /* + */
  --comment-actions-margin-top: var(--margin-sm); /* FIXME */
  /* + */
  --comment-content-detail-p-margin-bottom: inherit;

  /* Comment —————————————————————————————— End */

  /* Tabs —————————————————————————————— Start */

  --tabs-card-head-background: var(--background-color-light);
  --tabs-card-height: 40px;
  --tabs-card-active-color: var(--primary-color);
  /* --tabs-card-horizontal-padding: calc(((var(--tabs-card-height) - floor(var(--font-size-base) * var(--line-height-base))) / 2) - var(--border-width-base)) var(--padding-md); */
  --tabs-card-horizontal-padding-sm: 6px var(--padding-md);
  --tabs-card-horizontal-padding-lg: 7px var(--padding-md) 6px;
  --tabs-title-font-size: var(--font-size-base);
  --tabs-title-font-size-lg: var(--font-size-lg);
  --tabs-title-font-size-sm: var(--font-size-base);
  --tabs-ink-bar-color: var(--primary-color);
  --tabs-bar-margin: 0 0 var(--margin-md) 0; /* FIXME */
  --tabs-horizontal-gutter: 32px;
  --tabs-horizontal-margin: 0 0 0 var(--tabs-horizontal-gutter);
  --tabs-horizontal-margin-rtl: 0 0 0 32px;
  --tabs-horizontal-padding: var(--padding-sm) 0;
  --tabs-horizontal-padding-lg: var(--padding-md) 0;
  --tabs-horizontal-padding-sm: var(--padding-xs) 0;
  --tabs-vertical-padding: var(--padding-xs) var(--padding-lg);
  --tabs-vertical-margin: var(--margin-md) 0 0 0; /* FIXME */
  --tabs-scrolling-size: 32px;
  --tabs-highlight-color: var(--primary-color);
  --tabs-hover-color: var(--primary-5);
  --tabs-active-color: var(--primary-7);
  --tabs-card-gutter: 2px;
  --tabs-card-tab-active-border-top: 2px solid transparent;

  /* Tabs —————————————————————————————— End */

  /* Back to top —————————————————————————————— Start */

  /* + */
  --back-top-color: var(--white);
  /* + */
  --back-top-bg: var(--text-color-secondary);
  /* + */
  --back-top-hover-bg: var(--text-color);

  /* Back to top —————————————————————————————— End */

  /* Avatar —————————————————————————————— Start */

  /* + */
  --avatar-size-base: 32px;
  /* + */
  --avatar-size-lg: 40px;
  /* + */
  --avatar-size-sm: 24px;
  /* + */
  --avatar-font-size-base: 18px;
  /* + */
  --avatar-font-size-lg: 24px;
  /* + */
  --avatar-font-size-sm: 14px;
  /* + */
  --avatar-bg: #d1d7de;
  /* + */
  --avatar-color: var(--dark-transparent-2);
  --avatar-border-color: var(--dark-transparent-7);
  --avatar-border-radius: var(--border-radius-base);
  /* + */
  --avatar-group-overlapping: -16px;
  /* + */
  --avatar-group-space: 3px;
  /* + */
  --avatar-group-border-color: var(--avatar-border-color);
  --avatar-group-border-width: var(--border-width-2);

  /* Avatar —————————————————————————————— End */

  /* Switch —————————————————————————————— Start */

  /* + */
  --switch-height: 22px;
  --switch-sm-height: 16px;
  --switch-min-width: 44px;
  /* + */
  --switch-sm-min-width: 28px;
  /* + */
  --switch-disabled-opacity: 0.4;
  --switch-color: var(--primary-color);
  --switch-bg: var(--component-background);
  /* + */
  --switch-shadow-color: rgba(0, 35, 11, 0.2);
  --switch-padding: 2px;
  /* + */
  --switch-inner-margin-min: 7px;
  /* + */
  --switch-inner-margin-max: 25px;
  /* + */
  --switch-sm-inner-margin-min: 5px;
  /* + */
  --switch-sm-inner-margin-max: 18px;

  /* Switch —————————————————————————————— End */

  /* Pagination —————————————————————————————— Start */

  /* + */
  --pagination-item-bg: var(--component-background);
  /* + */
  --pagination-item-size: var(--height-base);
  /* + */
  --pagination-item-size-sm: 24px;
  /* + */
  --pagination-font-family: var(--font-family);
  /* + */
  --pagination-font-weight-active: 500;
  /* + */
  --pagination-item-bg-active: var(--component-background);
  /* + */
  --pagination-item-link-bg: var(--component-background);
  /* + */
  --pagination-item-disabled-color-active: var(--white);
  /* + */
  --pagination-item-disabled-bg-active: #dbdbdb;
  /* + */
  --pagination-item-input-bg: var(--component-background);
  /* + */
  --pagination-mini-options-size-changer-top: 0px;

  /* Pagination —————————————————————————————— End */

  /* Page header —————————————————————————————— Start */

  /* + */
  --page-header-padding: var(--padding-lg);
  /* + */
  --page-header-padding-vertical: var(--padding-md);
  /* + */
  --page-header-padding-breadcrumb: var(--padding-sm);
  /* + */
  --page-header-content-padding-vertical: var(--padding-sm);
  /* + */
  --page-header-back-color: var(--text-color); /* Not used */
  /* + */
  --page-header-ghost-bg: inherit;
  /* + */
  --page-header-heading-title: var(--heading-4-size);
  /* + */
  --page-header-heading-sub-title: 14px;
  /* + */
  --page-header-tabs-tab-font-size: 16px;

  /* Page header —————————————————————————————— End */

  /* Breadcrumb —————————————————————————————— Start */

  /* + */
  --breadcrumb-base-color: var(--text-color-secondary);
  /* + */
  --breadcrumb-last-item-color: var(--text-color);
  /* + */
  --breadcrumb-font-size: var(--font-size-base);
  /* + */
  --breadcrumb-icon-font-size: var(--font-size-base);
  /* + */
  --breadcrumb-link-color: var(--text-color-secondary);
  /* + */
  --breadcrumb-link-color-hover: var(--primary-5);
  /* + */
  --breadcrumb-separator-color: var(--text-color-secondary);
  /* + */
  --breadcrumb-separator-margin: 0 var(--padding-xs);

  /* Breadcrumb —————————————————————————————— End */

  /* Slider —————————————————————————————— Start */

  /* + */
  --slider-margin: 10px 6px 10px;
  /* + */
  --slider-rail-background-color: var(--background-color-base);
  /* + */
  --slider-rail-background-color-hover: #e1e1e1;
  /* + */
  --slider-track-background-color: var(--primary-3);
  /* + */
  --slider-track-background-color-hover: var(--primary-4);
  /* + */
  --slider-handle-border-width: 2px;
  /* + */
  --slider-handle-background-color: var(--component-background);
  /* + */
  --slider-handle-color: var(--primary-3);
  /* + */
  --slider-handle-color-hover: var(--primary-4);
  /* + */
  --slider-handle-color-focus: #46a6ff;
  /* + */
  --slider-handle-color-focus-shadow: rgba(24, 144, 255, 0.12);
  /* + */
  --slider-handle-color-tooltip-open: var(--primary-color);
  /* + */
  --slider-handle-size: 14px;
  /* + */
  --slider-handle-margin-top: -5px;
  /* + */
  --slider-handle-shadow: 0;
  /* + */
  --slider-dot-border-color: var(--border-color-split);
  /* + */
  --slider-dot-border-color-active: #8cc8ff;
  /* + */
  --slider-disabled-color: var(--disabled-color);
  /* + */
  --slider-disabled-background-color: var(--component-background);

  /* Slider —————————————————————————————— End */

  /* Tree —————————————————————————————— Start */

  --tree-bg: var(--component-background);
  --tree-title-height: 24px;
  --tree-child-padding: 18px;
  --tree-directory-selected-color: var(--white);
  --tree-directory-selected-bg: var(--primary-color);
  --tree-node-hover-bg: var(--item-hover-bg);
  --tree-node-selected-bg: var(--primary-2);

  /* Tree —————————————————————————————— End */

  /* Collapse —————————————————————————————— Start */

  /* + */
  --collapse-header-padding: var(--padding-sm) var(--padding-md);
  /* + */
  --collapse-header-padding-extra: 40px;
  /* + */
  --collapse-header-bg: var(--dark-transparent-9);
  /* + */
  --collapse-content-padding: var(--padding-md);
  /* + */
  --collapse-content-bg: var(--white-transparent-1);
  /* + */
  --collapse-header-arrow-left: 16px;

  /* Collapse —————————————————————————————— End */

  /* Transfer —————————————————————————————— Start */

  /* + */
  --transfer-header-height: 40px;
  --transfer-item-height: var(--height-base);
  --transfer-disabled-bg: var(--disabled-bg);
  --transfer-list-height: 200px;
  --transfer-item-hover-bg: var(--item-hover-bg);
  --transfer-item-padding-vertical: 6px;
  --transfer-list-search-icon-top: 12px;

  /* Transfer —————————————————————————————— End */

  /* Message —————————————————————————————— Start */

  /* + */
  --message-notice-content-padding: 10px 16px;
  /* + */
  --message-notice-content-bg: var(--component-background);
  --wave-animation-width: 6px;

  /* Message —————————————————————————————— End */

  /* Alert —————————————————————————————— Start */

  /* + */
  --alert-success-border-color: transparent;
  /* + */
  --alert-success-bg-color: var(--success-1);
  /* + */
  --alert-success-icon-color: var(--success-color);
  /* + */
  --alert-info-border-color: transparent;
  /* + */
  --alert-info-bg-color: var(--primary-1);
  /* + */
  --alert-info-icon-color: var(--info-color);
  /* + */
  --alert-warning-border-color: transparent;
  /* + */
  --alert-warning-bg-color: var(--warning-1);
  /* + */
  --alert-warning-icon-color: var(--warning-color);
  /* + */
  --alert-error-border-color: transparent;
  /* + */
  --alert-error-bg-color: var(--error-1);
  /* + */
  --alert-error-icon-color: var(--error-color);
  /* + */
  --alert-message-color: var(--dark-transparent-1);
  /* + */
  --alert-text-color: var(--text-color);
  /* + */
  --alert-close-color: var(--text-color-secondary);
  /* + */
  --alert-close-hover-color: var(--icon-color-hover);
  /* + */
  --alert-no-icon-padding-vertical: var(--padding-xs);
  /* + */
  --alert-with-description-no-icon-padding-vertical: calc(
    var(--padding-md) - 1px
  );
  /* + */
  --alert-with-description-padding-vertical: calc(var(--padding-md) - 1px);
  --alert-with-description-padding: var(
      --alert-with-description-padding-vertical
    )
    15px;
  --alert-icon-top: calc(
    8px + var(--font-size-base) * (var(--line-height-base) / 2) -
      (var(--font-size-base) / 2)
  );
  /* + */
  --alert-with-description-icon-size: 24px;

  /* Alert —————————————————————————————— End */

  /* Statistic —————————————————————————————— Start */

  /* + */
  --statistic-title-font-size: var(--font-size-base);
  /* + */
  --statistic-content-font-size: 24px;
  --statistic-unit-font-size: 24px;
  /* + */
  --statistic-font-family: var(--font-family);

  /* Statistic —————————————————————————————— End */

  /* Drawer —————————————————————————————— Start */

  /* + */
  --drawer-header-padding: var(--padding-md) var(--padding-lg);
  /* + */
  --drawer-body-padding: var(--padding-lg);
  /* + */
  --drawer-bg: var(--component-background);
  /* + */
  --drawer-footer-padding-vertical: var(--modal-footer-padding-vertical);
  /* + */
  --drawer-footer-padding-horizontal: var(--modal-footer-padding-horizontal);
  --drawer-header-close-size: 56px;

  /* Drawer —————————————————————————————— End */

  /* Timeline —————————————————————————————— Start */

  /* + */
  --timeline-width: 2px;
  /* + */
  --timeline-color: var(--dark-transparent-7);
  /* + */
  --timeline-dot-border-width: 2px;
  /* + */
  --timeline-dot-color: var(--primary-color);
  /* + */
  --timeline-dot-bg: transparent;
  /* + */
  --timeline-item-padding-bottom: 20px;

  /* Timeline —————————————————————————————— End */

  /* Upload —————————————————————————————— Start */

  /* + */
  --upload-actions-color: var(--text-color-secondary);

  /* Upload —————————————————————————————— End */

  /* Steps —————————————————————————————— Start */

  --process-tail-color: var(--border-color-split);
  --steps-nav-arrow-color: rgba(0 0 0, 0.25);
  --steps-background: var(--component-background);
  /* + */
  --steps-icon-size: 32px;
  --steps-icon-custom-size: var(--steps-icon-size);
  --steps-icon-custom-top: 0px;
  --steps-icon-custom-font-size: 24px;
  /* + */
  --steps-icon-top: -0.5px;
  --steps-icon-font-size: var(--font-size-lg);
  /* + */
  --steps-icon-margin: 0 8px 0 0;
  /* + */
  --steps-title-line-height: var(--height-base);
  --steps-small-icon-size: 24px;
  --steps-small-icon-margin: 0 8px 0 0;
  /* + */
  --steps-dot-size: 8px;
  /* + */
  --steps-dot-top: 2px;
  /* + */
  --steps-current-dot-size: 10px;
  /* + */
  --steps-description-max-width: 140px;
  /* + */
  --steps-nav-content-max-width: auto;
  --steps-vertical-icon-width: 16px;
  --steps-vertical-tail-width: 16px;
  --steps-vertical-tail-width-sm: 12px;

  /* Steps —————————————————————————————— End */

  /* Notification —————————————————————————————— Start */

  /* + */
  --notification-bg: var(--component-background);
  /* + */
  --notification-padding-vertical: 16px;
  /* + */
  --notification-padding-horizontal: 24px;

  /* Notification —————————————————————————————— End */

  /* Result —————————————————————————————— Start */

  /* + */
  --result-title-font-size: 24px;
  /* + */
  --result-subtitle-font-size: var(--font-size-base);
  /* + */
  --result-icon-font-size: 72px;
  /* + */
  --result-extra-margin: 24px 0 0 0;

  /* Result —————————————————————————————— End */

  /* Image —————————————————————————————— Start */

  --image-size-base: 48px;
  --image-font-size-base: 24px;
  --image-bg: #f5f5f5;
  --image-color: var(--white);
  --image-mask-font-size: 16px;
  --image-preview-operation-size: 18px;
  --image-preview-operation-color: var(--text-color-dark);
  /* + */
  --image-preview-operation-disabled-color: rgba(255, 255, 255, 0.25);

  /* Image —————————————————————————————— End */

  /* Custom vars —————————————————————————————— Start */
  /* Chat */

  --chat-bg: var(--white-transparent-6); /* chat view in list of chats */
  --chat-active-bg: var(--white-transparent-2); /* active chat in chats list */

  --message-bg: var(--white-transparent-2);
  --message-primary-bg: var(--orange-transparent-1); /* my sent message */

  --message-color: var(--dark-transparent-2); /* text color for message */

  --message-info-color: var(--dark-2); /* text color for time in message*/
  --message-info-primary-color: var(
    --white-transparent-2
  ); /* text color for time and "dots" in message */

  /* User show card */
  --user-show-card-bg: var(--white-transparent-3);

  /* Popover */
  --custom-popover-bg: var(--gray-5);

  /* Date label */
  --date-label-bg: var(--white-transparent-2);
  --date-label-border-radius: var(--border-radius-xl);
  --date-label-color: var(--text-color);

  /* Popup */
  --popup-bg: var(--component-background);

  /* Agreement template preview */
  --agreement-template-preview-bg: var(--dark-transparent-9);

  /* Upload button */
  --btn-upload-bg: var(--input-bg);
  --btn-upload-border-color: var(--dark-transparent-8);

  /* Custom vars —————————————————————————————— End */
}
