:root.dark {

  /* Accent —————————————————————————————— Start */
  --ql-color-accent1: rgb(246, 112, 77);

  /* Transparent shades */
  --ql-color-accent1-t-lighten1: rgb(246, 112, 77, var(--ql-color-blending-lighten1));
  --ql-color-accent1-t-lighten2: rgb(246, 112, 77, var(--ql-color-blending-lighten2));
  --ql-color-accent1-t-lighten3: rgb(246, 112, 77, var(--ql-color-blending-lighten3));
  --ql-color-accent1-t-lighten4: rgb(246, 112, 77, var(--ql-color-blending-lighten4));
  --ql-color-accent1-t-lighten5: rgb(246, 112, 77, var(--ql-color-blending-lighten5));
  --ql-color-accent1-t-lighten6: rgb(246, 112, 77, var(--ql-color-blending-lighten6));
  /* Accent —————————————————————————————— Finish */

  /* Dark —————————————————————————————— Start */
  --ql-color-dark: rgb(17, 17, 17);

  /* Transparent shades */
  --ql-color-dark-t-lighten1: rgb(17, 17, 17, var(--ql-color-blending-lighten1));
  --ql-color-dark-t-lighten2: rgb(17, 17, 17, var(--ql-color-blending-lighten2));
  --ql-color-dark-t-lighten3: rgb(17, 17, 17, var(--ql-color-blending-lighten3));
  --ql-color-dark-t-lighten4: rgb(17, 17, 17, var(--ql-color-blending-lighten4));
  --ql-color-dark-t-lighten5: rgb(17, 17, 17, var(--ql-color-blending-lighten5));
  --ql-color-dark-t-lighten6: rgb(17, 17, 17, var(--ql-color-blending-lighten6));
  /* Dark —————————————————————————————— Finish */

  /* Gradient —————————————————————————————— Start */
  --ql-gradient-body:  linear-gradient(-30deg, #17181c -20%, #272831);
  /* Gradient —————————————————————————————— Finish */

  /* Danger —————————————————————————————— Start */
  --ql-color-danger: rgb(245, 34, 45);

  /* Transparent shades */
  --ql-color-danger-t-lighten1: rgb(245, 34, 45, var(--ql-color-blending-lighten1));
  --ql-color-danger-t-lighten2: rgb(245, 34, 45, var(--ql-color-blending-lighten2));
  --ql-color-danger-t-lighten3: rgb(245, 34, 45, var(--ql-color-blending-lighten3));
  --ql-color-danger-t-lighten4: rgb(245, 34, 45, var(--ql-color-blending-lighten4));
  --ql-color-danger-t-lighten5: rgb(245, 34, 45, var(--ql-color-blending-lighten5));
  --ql-color-danger-t-lighten6: rgb(245, 34, 45, var(--ql-color-blending-lighten6));
  /* Danger —————————————————————————————— Finish */

  /* Warning —————————————————————————————— Start */
  --ql-color-warning: rgb(250, 173, 20);

  /* Transparent shades */
  --ql-color-warning-t-lighten1: rgb(250, 173, 20, var(--ql-color-blending-lighten1));
  --ql-color-warning-t-lighten2: rgb(250, 173, 20, var(--ql-color-blending-lighten2));
  --ql-color-warning-t-lighten3: rgb(250, 173, 20, var(--ql-color-blending-lighten3));
  --ql-color-warning-t-lighten4: rgb(250, 173, 20, var(--ql-color-blending-lighten4));
  --ql-color-warning-t-lighten5: rgb(250, 173, 20, var(--ql-color-blending-lighten5));
  --ql-color-warning-t-lighten6: rgb(250, 173, 20, var(--ql-color-blending-lighten6));
  /* Warning —————————————————————————————— Finish */

  /* Layout —————————————————————————————— Start */
  --layout-body-background: var(--ql-gradient-body);
  --ql-layout-padding-default: 16px;
  /* Layout —————————————————————————————— Finish */

  /* Font family —————————————————————————————— Start */
  --ql-font-family-main:  "Rubik";
  /* Font family —————————————————————————————— Finish */

  /* Settings —————————————————————————————— Start */
  --ql-typography-heading-color: var(--ql-color-white);
  --ql-typography-text-color-primary: var(--ql-color-white);
  --ql-typography-text-color-primary-inverse: var(--ql-color-white);
  --ql-typography-text-color-secondary: var(--ql-color-white-t-lighten1);
  /* Settings —————————————————————————————— Finish */

  /* Components —————————————————————————————— Start */
  --ql-component-height-md: 46px;
  /* Components —————————————————————————————— Finish */

  /* Alert —————————————————————————————— Start */
  --alert-success-border-color: transparent;
  --alert-success-bg-color: var(--ql-color-success-t-lighten1);

  --alert-info-border-color: transparent;
  --alert-info-bg-color: var(--ql-color-accent1-t-lighten1);

  --alert-warning-border-color: transparent;
  --alert-warning-bg-color: var(--ql-color-warning-t-lighten1);

  --alert-error-border-color: transparent;
  --alert-error-bg-color: var(--ql-color-danger-t-lighten1);

  --alert-message-color: var(--ql-typography-text-color-secondary);
  /* Alert —————————————————————————————— Finish */

  /* Avatar —————————————————————————————— Start */
  --ql-avatar-bg: rgb(89, 89, 89);
  --avatar-color: var(--ql-color-white-t-lighten2);
  --avatar-border-color: var(--ql-avatar-bg);

  --avatar-group-overlapping: -16px;
  --avatar-group-border-color: var(--avatar-border-color);
  --avatar-group-border-width: var(--ql-border-width-2);
  /* Avatar —————————————————————————————— Finish */

  /* Button —————————————————————————————— Start */
  --btn-icon-size: 24px;

  --btn-font-weight: var(--ql-font-weight-semibold);

  --btn-default-color: var(--ql-color-white-t-lighten2);
  --btn-default-bg: var(--ql-color-white-t-lighten3);
  --btn-default-border: transparent;

  --btn-default-hover-border: transparent;
  --btn-default-hover-color:  var(--ql-color-white-t-lighten1);
  --btn-default-hover-bg: var(--ql-color-white-t-lighten4);

  --btn-primary-border: transparent;

  --btn-primary-hover-border: transparent;

  --btn-danger-border: transparent;

  --btn-white-hover-color: var(--ql-color-dark);

  --btn-text-color: var(--ql-typography-text-color-primary);

  --btn-text-hover-bg: var(--ql-color-white-t-lighten6);
  --btn-text-hover-color: var(--ql-typography-text-color-primary);

  --btn-disable-border: var(--ql-color-dark-t-lighten4);

  --btn-group-border: var(--ql-color-accent1);

  --btn-upload-bg: var(--input-bg);
  --btn-upload-border-color: var(--ql-color-white-t-lighten4);
  /* Button —————————————————————————————— Finish */

  /* Card —————————————————————————————— Start */
  --card-background: var(--ql-color-white-t-lighten5);
  /* custom var for card on userShow page */
  --card-user-show-bg: var(--ql-color-white-t-lighten5);
  /* Card —————————————————————————————— Finish */

 /* Cascader —————————————————————————————— Start */
  --cascader-item-selected-bg: var(--ql-color-accent1-t-lighten1);
 /* Cascader —————————————————————————————— Finish */

  /* Chat —————————————————————————————— Start */
  --chat-bg: var(--ql-color-white-t-lighten6); /* chat view in list of chats */
  --chat-active-bg: var(--ql-color-white-t-lighten5); /* active chat in chats list */

  --message-bg: var(--ql-color-dark-t-lighten6);
  --message-primary-bg: var(--ql-color-accent1); /* my sent message */

  --message-color: var(--ql-color-white-t-lighten2); /* text color for message */
  --message-primary-color: var(--ql-color-white-t-lighten1); /* text color for my sent message */

  --message-info-color: var(--ql-color-white-t-lighten2); /* text color for time in message*/
  --message-info-primary-color: var(
    --ql-color-white-t-lighten2
  ); /* text color for time and "dots" in message */
  /* Chat —————————————————————————————— Finish */

  /* DateLabel —————————————————————————————— Start */
  --date-label-bg: var(--ql-color-white-t-lighten4);
  --date-label-border-radius: var(--ql-border-radius-16);
  --date-label-color: var(--ql-typography-text-color);
  /* DateLabel —————————————————————————————— Finish */

  /* Divider —————————————————————————————— Start */
  --divider-color: var(--text-color-secondary);
  /* Divider —————————————————————————————— Finish */

  /* Dropdown —————————————————————————————— Start */
  --dropdown-menu-bg: var(--select-dropdown-bg);
  --dropdown-menu-padding: 4px 0;

  --dropdown-vertical-padding: 5px;

  --dropdown-font-size: var(--ql-typography-font-size-md);
  --dropdown-line-height: var(--ql-typography-line-height-md);

  --dropdown-item-padding: 0px 16px;

  --dropdown-item-default-bg-hover: var(--ql-color-white-t-lighten6);

  --dropdown-item-danger-bg-hover: var(--ql-color-danger-t-lighten5);

  --dropdown-menu-submenu-disabled-bg: var(--component-background);
  /* Dropdown —————————————————————————————— Finish */

  /* Input —————————————————————————————— Start */
  --input-bg: var(--ql-color-white-t-lighten5);
  --input-addon-bg: var(--ql-typography-text-color-secondary);

  --input-border-color: transparent;


  --input-placeholder-color: var(--ql-color-white-t-lighten2);

  --input-number-handler-bg: var(--input-bg);
  /* Input —————————————————————————————— Finish */

  /* List —————————————————————————————— Start */
  --list-item-border-color: var(--ql-color-white-t-lighten5);
  --item-active-bg: var(--ql-color-accent1-t-lighten6);
  --item-hover-bg: var(--ql-color-accent1);
  /* List —————————————————————————————— Finish */

  /* Menu —————————————————————————————— Start */
  --menu-item-icon-margin-right: 10px;
  --menu-item-color-hover: var(--ql-color-accent1);
  --menu-item-active-color: var(--ql-color-dark);
  --menu-item-active-bg: var(--ql-color-accent1-t-lighten4);
  --menu-horizontal-main-item-color-hover: var(--ql-typography-text-color-secondary);
  /* Menu —————————————————————————————— Finish */

  /* Message —————————————————————————————— Start */
  --message-notice-content-bg: rgb(38, 38, 38);
  /* Message —————————————————————————————— Finish */

  /* Pagination —————————————————————————————— Start */
  --pagination-font-weight-active: var(--ql-font-weight-medium);
  --pagination-item-bg-active: var(--ql-color-white-t-lighten6);
  --pagination-item-link-bg: var(--ql-color-white-t-lighten5);
  /* Pagination —————————————————————————————— Finish */

  /* Picker —————————————————————————————— Start */
  --picker-basic-cell-hover-color: var(--item-hover-bg);
  --picker-basic-cell-active-with-range-color: var(--ql-color-accent1-t-lighten1);
  /* Picker —————————————————————————————— Finish */

  /* Popover —————————————————————————————— Start */
  --popover-bg: rgb(67, 67, 67);
  /* Popover —————————————————————————————— Finish */

  /* Progress —————————————————————————————— Start */
  --progress-remaining-color: var(--ql-color-white-t-lighten6);
  --progress-steps-item-bg: var(--ql-color-white-t-lighten6);
  --progress-text-font-size: var(--ql-typography-font-size-md);
  --progress-circle-text-font-size: var(--progress-text-font-size);
  /* Progress —————————————————————————————— Finish */

  /* Rate —————————————————————————————— Start */
  --rate-star-color: var(--ql-color-accent1);
  /* Rate —————————————————————————————— Finish */

  /* Result —————————————————————————————— Start */
  --result-title-font-size: var(--ql-font-size-h3);
  /* Result —————————————————————————————— Finish */

  /* Select —————————————————————————————— Start */
  --ql-select-border-color: var(--input-border-color);
  --ql-select-item-selected-font-weight: var(--ql-font-weight-medium);
  --ql-select-dropdown-bg: rgb(38, 38, 38);
  --ql-select-item-hover: var(--ql-color-white-t-lighten6);
  --ql-select-item-selected-bg: var(--ql-color-accent1);
  --ql-select-item-active-bg: var(--ql-color-accent1);

  --ql-select-dropdown-vertical-padding: 4px 0;
  --ql-select-dropdown-font-size: var(--ql-typography-font-size-md);
  --ql-select-dropdown-line-height: var(--ql-typography-line-height-md);
  --ql-select-background: var(--ql-color-dark-t-lighten6);
  --ql-select-selection-item-bg: var(--ql-color-accent1);
  /* Select —————————————————————————————— Finish */

  /* Skeleton —————————————————————————————— Start */
  --skeleton-color: var(--ql-color-dark-t-lighten3);
  --skeleton-to-color: var(--ql-color-dark-t-lighten1);
  /* Skeleton —————————————————————————————— Finish */

  /* Disabled —————————————————————————————— Start */
  --ql-state-disabled-color: var(--ql-color-white-t-lighten3);
  --ql-state-disabled-bg: var(--ql-color-dark-t-lighten6);
  /* Disabled —————————————————————————————— Finish */

  /* Popup —————————————————————————————— Start */
  --popup-bg: rgb(35, 35, 35);
  /* Popup —————————————————————————————— Finish */

  /* Table —————————————————————————————— Start */
  --table-bg: var(--ql-color-white-t-lighten5);
  --table-row-hover-bg: var(--ql-color-dark-t-lighten5);
  --table-selected-row-bg: var(--ql-color-accent1-t-lighten5);
  /* Table —————————————————————————————— Finish */

  /* Timeline —————————————————————————————— Start */
  --timeline-color: var(--ql-color-white-t-lighten4);
  --timeline-dot-bg: var(--ql-color-dark-t-lighten5);
  /* Timeline —————————————————————————————— Finish */

  /* Tag —————————————————————————————— Start */
  --tag-default-bg: var(--ql-color-dark-t-lighten6);
  /* Tag —————————————————————————————— Finish */

  /* Typeform —————————————————————————————— Start */

  /* static list */
  --qf-list-item-bg: var(--ql-color-white-t-lighten5);
  --qf-list-item-hover: var(--ql-color-white-t-lighten3);

  --qf-list-item-preview: var(--ql-color-white-t-lighten5);

  /* drag and drop */
  --qf-dnd-item-badge-bg: var(--ql-color-accent1);
  --qf-dnd-item-badge-color: var(--ql-color-white);

  --qf-dnd-item-bg: var(--ql-color-white-t-lighten5);
  --qf-dnd-item-hover: var(--ql-color-white-t-lighten3);

  --qf-dnd-active-item-bg: var(--ql-color-accent1-t-lighten5);
  --qf-dnd-active-item-hover: var(--ql-color-accent1-t-lighten3);

  /* content card */
  --qf-content-card-bg: var(--ql-color-white-t-lighten6);

  /* layout */
  --qf-sidebar-bg: transparent;
  --qf-header-mb: 8px;
  --qf-sidebar-width: 350px;

  /* button */
  --qf-button-bg: var(--ql-color-accent1-t-lighten4);
  --qf-button-hover: var(--ql-color-accent1-t-lighten3);
  --qf-active-button-bg: var(--ql-color-accent1-t-lighten2);

  --qf-active-keybox-bg: var(--ql-color-accent1-t-lighten4);
  --qf-keybox-bg: var(--ql-color-accent1-t-lighten4);

  --qf-button-color: var(--ql-color-accent1);
  --qf-active-button-color: var(--ql-color-accent1);
  --qf-submit-button-font-size: var(--ql-font-size-h4);

  /* input */
  --qf-input-background: var(--ql-color-dark-t-lighten5); /*

 /* overwritten menu */
  --menu-vertical-inline-item-padding: 8px;

  /* overwritten form */
  --ql-form-item-vertical-spacing: 24px;

  /* tag */
  --qf-tag-color: var(--ql-color-accent1);

  /* uploader */
  --qf-uploader-color: var(--ql-color-white);
  --qf-uploader-bg: var(--ql-color-accent1);
  --qf-uploader-hover: var(--ql-color-accent1-t-lighten1);

  --qf-uploader-item-bg: var(--ql-color-dark-t-lighten5);

  /* domain component: question */
  --qf-question-type-icon-default: var(--ql-color-dark-t-lighten4);
  --qf-question-type-icon-danger: var(--ql-color-danger-t-lighten3);
  --qf-question-header-font-size: var(--ql-font-size-h3);

  /*domain component: condition */
  --qf-condition-item-bg: var(--ql-color-dark-t-lighten6);
  --qf-condition-item-border: var(--ql-color-dark-t-lighten4);

  /* typography */
  --qf-typography-fs-body: var(--ql-font-size-body1);
  --qf-typography-fs-caption: var(--ql-font-size-caption1);

  --qf-typography-title-color: var(--ql-color-white);
  --qf-typography-subtitle-color: var(--ql-color-white-t-lighten1);
  --qf-typography-caption-color: var(--ql-color-white-t-lighten2);

  /* border radius */
  --qf-border-radius-sm: var(--ql-border-radius-sm); /* 6px */
  --qf-border-radius-md: var(--ql-border-radius-md); /* 8px */
  --qf-border-radius-lg: var(--ql-border-radius-16); /* 12px */
  --qf-border-radius-full: var(--ql-border-radius-full);

  /* [OPTIONAL] */
  --ql-body-bg: var(--ql-color-dark-t-lighten6);

  /* Typeform —————————————————————————————— Finish */

  /* Grid —————————————————————————————— Start */
  --ql-grid-gutter-xs: 8px;
  /* Grid —————————————————————————————— Finish */

  /* Picker —————————————————————————————— Start */
  --picker-bg: var(--dropdown-menu-bg)
  /* Picker —————————————————————————————— Finish */


}
